import { invokeAppSync } from '../libs/authLib';
import { getApiPrefix } from '../libs/apiLib';
import config from '../config';
import { createInputFields, createSelectionSet } from './apiUtil';
import { ITagInput } from '../interfaces/Tags/ITagInput';
import { TagTypeFields } from '../interfaces/Tags/TagTypeFields';
import { ISaveTagInputType } from '../interfaces/Tags/ISaveTagInputType';
import { IDeleteTagInputType } from '../interfaces/Tags/IDeleteTagInputType';
import { ITagPriority } from '../interfaces/ITagPriority';


const baseAppSyncUrl = () => getApiPrefix();

const checkAppSyncUrlExists = () => {
  return !!config.appSync['VRS_APPSYNC_SYSTEM_URL'];
};

/**
 * Fetches tags from the System API.
 *
 * @param {ITagInput} tagInput - The input parameters for fetching tags.
 * @param {TagTypeFields[]} selectionSet - The fields to be selected in the query.
 * @returns {Promise<any>} - A promise that resolves to the fetched tags.
 * @throws {Error} - Throws an error if no data is found.
 */
class VrsTagsApi {
  static async getTags(tagInput: ITagInput, selectionSet: TagTypeFields[]): Promise<any> {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }

    const inputFields = createInputFields(tagInput);
    const selectionFields = createSelectionSet(selectionSet);

    const graphQLBody = {
      query: `
      query tagsQuery {
        tags(tagsInput: {${inputFields}}) {
          ${selectionFields}
        }
      }
    `,
    };

    const result = await invokeAppSync({
      gatewayName: 'VRS_APPSYNC_SYSTEM',
      path: baseAppSyncUrl(),
      method: 'POST',
      body: graphQLBody,
    });

    if (result.data?.tags) {
      return result.data.tags;
    } else {
      throw new Error('No data found');
    }
  }

  /**
   * Saves a tag to the System API.
   *
   * @template T
   * @param {T} action - The action to be performed ('Create', 'Update', or 'Delete').
   * @param {T extends 'Delete' ? IDeleteTagInputType : ISaveTagInputType} tag - The tag data to be saved.
   * @returns {Promise<Number>} - A promise that resolves when the tag is saved with the tag ID.
   * @throws {Error} - Throws an error if the tag could not be saved.
   */
  static async saveTag<T extends 'Create' | 'Update' | 'Delete'>(
    action: T,
    tag: T extends 'Delete' ? IDeleteTagInputType : ISaveTagInputType
  ): Promise<number> {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve(0);
    }

    const tagFields = Object.entries(tag)
      .filter(([_, value]) => value !== undefined && value !== '')
      .map(([key, value]) => `${key}: ${typeof value === 'string' ? `"${value}"` : value}`)
      .join(', ');

    const graphQLBody = {
      query: `
        mutation SaveTagMutation {
          saveTag(saveTagInput: {
            Action: "${action}",
            Tag: { ${tagFields} }
          })
        }
      `,
    };

    const result = await invokeAppSync({
      gatewayName: 'VRS_APPSYNC_SYSTEM',
      path: baseAppSyncUrl(),
      method: 'POST',
      body: graphQLBody,
    });

    if (!(result && result.data && result.data.saveTag)) {
      throw new Error('Failed to save tag');
    }
    return Number(result.data.saveTag);
  }

  // Method to fetch device tag relationships
  static async getDeviceTagsByTagIds(tagIds: number[]): Promise<any> {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }


    const graphQLBody = {
      query: `
      query devicesByTagIdsQuery {
        deviceTags(tagIds: [${tagIds.map(id => `${id}`).join(', ')}]) {
          DeviceId
          TagId
        }
      }
    `,
    };


    const result = await invokeAppSync({
      gatewayName: 'VRS_APPSYNC_SYSTEM',
      path: baseAppSyncUrl(),
      method: 'POST',
      body: graphQLBody,
    });

    if (result.data?.deviceTags) {
      return result.data.deviceTags;
    } else {
      throw new Error('No data found');
    }
  }

  /**
   * Saves device tags to the System API.
   *
   * @param {number} tagId - The ID of the tag.
   * @param {string[]} deviceTags - The array of device IDs.
   * @returns {Promise<void>} - A promise that resolves when the device tags are saved.
   * @throws {Error} - Throws an error if the device tags could not be saved.
   */
  static async saveDeviceTags(tagId: number, deviceTags: string[]): Promise<void> {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve();
    }

    const deviceIds = deviceTags.map(id => `"${id}"`).join(', ');

    const graphQLBody = {
      query: `
        mutation {
          tagDevices(tagDevicesInput: { TagId: ${tagId}, DeviceIds: [${deviceIds}] })
        }
      `,
    };

    const result = await invokeAppSync({
      gatewayName: 'VRS_APPSYNC_SYSTEM',
      path: baseAppSyncUrl(),
      method: 'POST',
      body: graphQLBody,
    });

    if (!(result && result.data && result.data.tagDevices)) {
      throw new Error('Failed to save device tags');
    }
  }

  static async savePriorities(siteId: number, priorities: ITagPriority[]): Promise<void>
  {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve();
    }

    const graphQLBody = {
      query: `
      mutation {
        saveTag(saveTagInput: {
          Action: "UpdatePriorities",
          SiteId: ${siteId},
          TagPriorityMap: [
            ${priorities.map(priority =>
        `{ TagId: ${priority.id}, Priority: ${priority.priority} }`
      ).join(', ')}
          ]
        })
      }
    `,
    };
    const result = await invokeAppSync({
      gatewayName: 'VRS_APPSYNC_SYSTEM',
      path: baseAppSyncUrl(),
      method: 'POST',
      body: graphQLBody,
    });

    if (!(result && result.data && result.data.saveTag)) {
      throw new Error('Failed to save device tags');
    }
  }
}

export default VrsTagsApi;
