import React from 'react';
import { Chip, Avatar, Tooltip } from '@mui/material';
import { Star } from '@mui/icons-material';


/**
 * Props for the SiteTag component.
 *
 * @typedef {Object} SiteTagProps
 * @property {string} label - The label to display on the tag.
 * @property {string} color - The background color of the tag.
 * @property {boolean} [accented=false] - Whether the tag is accented.
 * @property {boolean} [starred=false] - Whether the tag is starred, overrides accented.
 * @property {boolean} [selected=false] - Whether the tag is selected.
 * @property {string} [accentText] - The accent text to display inside the avatar.
 * @property {function} [onClick] - The function to call when the tag is clicked.
 */
interface SiteTagProps {
  label: string;
  color: string;
  accented?: boolean;
  starred?: boolean;
  selected?: boolean;
  accentText?: string;
  onClick?: () => void;
}

/**
 * SiteTag component to display a tag with optional avatar, star, and accent text.
 *
 * @param {SiteTagProps} props - The props for the component.
 * @returns {JSX.Element} The rendered SiteTag component.
 */
const SiteTag: React.FC<SiteTagProps> = ({ label, color, accented = false, starred = false, selected = false, accentText, onClick }: SiteTagProps): JSX.Element => {

  // https://www.w3.org/TR/WCAG20/#relativeluminancedef
  const darkColor = (color: string) => {
    const rgb = parseInt(color.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >>  8) & 0xff;
    const b = (rgb >>  0) & 0xff;
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luma < 140;
  };

  const useLightText = darkColor(color);
  const hoverStarColor = useLightText ? '#FFF' : '#000';
  const selectedStarColor = selected ? hoverStarColor : color;

  return (
    <Tooltip title={label}>
      <Chip
        avatar={starred ? <Star style={{ color: selectedStarColor, transition: 'color 0.3s' }} /> : (accentText || accented ? <Avatar style={{ backgroundColor: color, color: accentText ? hoverStarColor : 'transparent', border: selected ? `2px solid ${hoverStarColor}` : 'none' }} >{accentText}</Avatar> : undefined)}
        label={label}
        variant="outlined"
        style={{
          borderColor: color,
          backgroundColor: selected ? color : '#FFF',
          color: selected ? (useLightText ? '#FFF' : '#000') : '#000',
          transition: 'background-color 0.3s, color 0.3s',
          fontSize: '1rem',
          padding: '0.5rem',
          margin: '0.25rem',
        }}
        onMouseOver={(e) => {
          const target = e.currentTarget as HTMLElement;
          target.style.backgroundColor = color;
          target.style.color = useLightText ? '#FFF' : '#000';
          if (starred) {
            const starElement = target.querySelector('svg') as unknown as HTMLElement;
            if (starElement) {
              starElement.style.color = hoverStarColor;
            }
          }
          const avatarElement = target.querySelector('.MuiAvatar-root') as HTMLElement;
          if (avatarElement) {
            avatarElement.style.border = `2px solid ${hoverStarColor}`;
          }
        }}
        onMouseOut={(e) => {
          const target = e.currentTarget as HTMLElement;
          if (!selected) {
            target.style.backgroundColor = '#FFF';
            target.style.color = '#000';
            if (starred) {
              const starElement = target.querySelector('svg') as unknown as HTMLElement;
              if (starElement) {
                starElement.style.color = selected ? selectedStarColor : color;
              }
            }
            const avatarElement = target.querySelector('.MuiAvatar-root') as HTMLElement;
            if (avatarElement) {
              avatarElement.style.border = 'none';
            }
          }
        }}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export default SiteTag;
