import { useQuery, UseQueryOptions } from 'react-query';
import VrsDeviceApi from '../api/prodVrsDeviceApi';
import { DeviceTypeFields } from '../interfaces/DeviceInfo/DeviceTypeFields';
import VrsPrinterApi from '../api/prodVrsPrinterApi';

const fetchDevices = async (siteId: string | number, selectionSet: DeviceTypeFields[]) => {
  return await VrsDeviceApi.getDevicesV2({ SiteId: Number(siteId) }, selectionSet);
};

const fetchPrinters = async (companyId: string, plantId: string) => {
  return await VrsPrinterApi.getPrinters(companyId, plantId);
};
/**
 * Custom hook to fetch devices using react-query.
 *
 * @param {string | number} siteId - The ID of the site for which to fetch devices.
 * @param {DeviceTypeFields[]} selectionSet - The fields to select for each device.
 * @param {UseQueryOptions} [options] - Optional configuration options for the useQuery hook.
 * @returns {object} The result of the useQuery hook, including the devices data and query status.
 */
const useFetchDevices = (siteId: string | number, selectionSet: DeviceTypeFields[], options?: UseQueryOptions<unknown, unknown, unknown, [string, string]>) => {
  return useQuery(['devices', String(siteId)], () => fetchDevices(siteId, selectionSet), {
    enabled: Boolean(siteId),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    ...options,
  });
};

/**
 * Custom hook to fetch printers using react-query.
 *
 * @param {string} companyId - The ID of the company for which to fetch printers.
 * @param {string} plantId - The ID of the plant for which to fetch printers.
 * @param {UseQueryOptions} [options] - Optional configuration options for the useQuery hook.
 * @returns {object} The result of the useQuery hook, including the printers data and query status.
 */
const useFetchPrinters = (companyId: string, plantId: string, options?: UseQueryOptions<unknown, unknown, unknown, [string, string, string]>) => {
  return useQuery(['printers', companyId, plantId], () => fetchPrinters(companyId, plantId), {
    enabled: Boolean(companyId && plantId),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    ...options,
  });
};

export { useFetchDevices, useFetchPrinters };
